import React, { useState, useEffect, useCallback } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Modal from "../components/Modal";
import html2pdf from 'html2pdf.js';

import "../css/report.scss";
import Button from "@mui/material/Button";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Image from "@tiptap/extension-image";
import {
  BubbleMenu,
  EditorContent,
  FloatingMenu,
  // Editor,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { TextSelection } from "prosemirror-state";
import Dropcursor from "@tiptap/extension-dropcursor";
import editTemplate from "../template/editTemplate";
import apiRequest from "../api/api";
import LinearProgress from "@mui/material/LinearProgress";
import { HeadingWithId, CustomImage } from "../js/headingWithID.js";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
// import exportToPDF from '../js/toPDF.js'
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Underline from '@tiptap/extension-underline';

import { imageContainer, figure, imageCaption } from "../components/imageContainer.jsx"


const maxFileSizeMB = 10;

const Report = () => {

  const [content, setContent] = useState(editTemplate);
  const [table, setTable] = useState([]);

  const getTable = () => {
    const doc = editor.state.doc;
    setTable(findHeadings(doc));
  };

  const editor = useEditor({
    extensions: [
      StarterKit, 
      Image.configure({
        allowBase64: true,
      }),
      // CustomImage, 
      HeadingWithId,
      imageContainer,
      figure,
      imageCaption
    ],
    content: content,
    onUpdate({ editor }) {
      getTable();
    },
  });

  //__________________________howie 20240827__________________________
  const addImage = useCallback(() => {
    const url = window.prompt('URL')
    
    // so this will add an image to where the cursor is 
    if (url) {
      editor.chain().focus().setImage({ src: url }).run()
    }
  }, [editor])
  //__________________________howie 20240827__________________________


  const test = async () => {};

  const downloadPdf = async () => {
    if (editor) {
      const content = editor.getHTML();

      // Create a temporary div to hold the content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = `
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Helvetica+Neue&display=swap');
          html { font-size: 14px; }
          body { font-family: 'Helvetica Neue', Arial, sans-serif; color: #333; line-height: 1.6; }
          .editbody { display: flex; gap: 20px; margin: 20px; }
          .tabel { min-height: 40vh; overflow-y: scroll; margin-top: 20px; width: 100%; white-space: normal; }
          .computing { overflow-y: scroll; min-height: 30vh; }
          .textcontent { white-space: nowrap; width: 340px; position: fixed; height: calc(100vh - 80px - 24px - 20px); }
          .tiptapArea { width: calc(100vw - 340px); margin-left: 340px; }
          .ProseMirror { background-color: white; padding: 20px; border-radius: 5px; margin: 10px; border: 1px solid transparent; min-height: 60vh; overflow-y: auto; }
          .ProseMirror p { font-size: 1rem; margin-bottom: 1.5rem; line-height: 1.6; }
          figure { display: flex; flex-direction: column; align-items: center; justify-content: space-between; width: 100%; overflow: hidden; }
          figure img { max-width: 100%; height: auto; object-fit: cover; margin: auto; border-radius: 15px; }
          imageCaption { font-size: 1em; color: #080808; margin-top: 8px; text-align: center; }
        </style>
        ${content}
      `;

      // Use html2pdf to download the content as a PDF
      html2pdf()
        .from(tempElement)
        .save();
    }
  };
  // const downloadPdf = async () => {
  //   if (editor) {
  //     const htmlContent = editor.getHTML();
  //     let response = await apiRequest(
  //       "generatePDF",
  //       "POST",
  //       JSON.stringify({ content: htmlContent })
  //     );

  //     if (response.ok) {
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = "report.pdf";
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //     } else {
  //       console.error("Failed to generate PDF");
  //     }
  //   }
  // };

  useEffect(() => {
    if (editor) {
      getTable();
    }
  }, [editor]);

  function findHeadings(node) {
    const headings = [];
    node.descendants((descendantNode, pos) => {
      if (descendantNode.type.name === "heading") {
        headings.push({
          level: descendantNode.attrs.level,
          text: descendantNode.textContent,
          position: pos,
        });
      }
    });
    return headings;
  }

  function scrollToNode(value) {
    const content = editor.getJSON().content;
    let sectionId = null;
    let index = 0;
    // Find the section in the JSON content
    for (let i = 0; i < content.length; i++) {
      if (content[i].type === "heading") {
        if (content[i].content[0].text === value.text) {
          sectionId = `section${index}`;
          break;
        }
        index += 1;
      }
    }

    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }

  const createNonBreakingSpaces = (count) =>
    Array(count * 2)
      .fill("\u00A0")
      .join("");

  const [errorAlert, seterrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUploadClick = async () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*, video/*,audio/*"; // Accept both image and video files
    fileInput.multiple = true; // Allow multiple files
    setErrorMessage("");

    fileInput.addEventListener("change", async (event) => {
      const selectedFiles = event.target.files;
      if (selectedFiles && selectedFiles.length > 0) {
        setErrorMessage("");
        const formData = new FormData();
        const pics = [];
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          //   if(file.type.startsWith('image/')){
          // pics.push(getBase64Image(selectedFiles[i]))
          // const base64Images = await Promise(getBase64Image(file));
          // pics.push(base64Images);

          // const reader = new FileReader();
          // pics.push(reader.readAsDataURL(file))
          // const base64Image = await getBase64Image(file); // 逐个读取文件
          // pics.push(base64Image); // 将 Base64 数据 URL 添加到数组
          // setPics(prevPics => [...prevPics, base64Image]);
          // };
          //   }

          if (file.size > maxFileSizeMB * 1024 * 1024) {
            setErrorMessage(
              `File ${file.name} exceeds the maximum size of ${maxFileSizeMB}MB`
            );
            return;
          }
          formData.append(`files${i}`, file);
        }

        console.log("?pics::", pics);

        setLoading(true);
        const response = await apiRequest("editToolPics", "POST", formData);
        if (response.ok) {
          const res = await response.json();
          editor.commands.setContent(res);
          // addAttchmentsToDocEnd(pics)
          getTable();
          setLoading(false);
          console.log("?Lo", loading);
        } else {
          setLoading(false);
        }
      } else {
        // Set an error message when the number of selected files is not within the allowed range
        setErrorMessage("Please select between 1 and 5 images");
      }
    });

    fileInput.click();
  };

  function getBase64Image(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  // const addAttchmentsToDocEnd = (pics) => {
  //     const doc = editor.state.doc;
  //     editor.chain().focus().setTextSelection({ from: doc.nodeSize - 2, to: doc.nodeSize - 2 }).run();
  //     const imageHtml = pics.map(pic => {
  //         // const url = URL.createObjectURL(pic);
  //         return `<img src="/Users/song/Desktop/AIPD2/aipd_testing/backend/uploadsImg/048.JPG" style={{ width: '50%', height: 'auto' }}/>`;
  //     }).join('');

  //     editor.commands.insertContent(imageHtml);
  // }

  useEffect(() => {
    const handleScroll = () => {
      var footer = document.querySelector(".footer");
      var dynamicDiv = document.querySelector(".dynamicBtn");
      var footerRect = footer.getBoundingClientRect();

      if (footerRect.top < window.innerHeight && footerRect.bottom >= 0) {
        dynamicDiv.style.bottom = footerRect.height + "px"; // Move up when footer is visible
      } else {
        dynamicDiv.style.bottom = "0";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // howie 20240903 mainly to add beam calculation tool
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {setIsModalOpen(false);};
  const handleBeam = () => {
    setIsModalOpen(true);
  }


  return (
    <div className="page">
      <Header />
      <div className="mainBody">
        <div>
          {loading ? (
            <div className="loadingPage">
              <div className="progressTips">
                {" "}
                Your report is being generated and may take a few minutes to
                complete.{" "}
              </div>
              <div className="progressTips">
                Please hold on while we prepare it for you.{" "}
              </div>
              <LinearProgress />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="editbody">
          <div className="textcontent">
            <div className="btns">
              <Button variant="contained" onClick={handleUploadClick}>
                Generate Report via Upload
              </Button>
            </div>
            {/* <div className="btns">
                    <Button variant="contained" onClick={test}>
                        Import your own file
                    </Button>
                </div> */}
            <div className="tabel">
              <h4>Table of Contents</h4>
              <div>
                {table.map((item, index) => (
                  <h5 key={index + "table"} onClick={() => scrollToNode(item)}>
                    {createNonBreakingSpaces(item.level) + item.text}
                  </h5>
                ))}
              </div>
            </div>
            {/* <div className="computing">
                    <h4>Portable Computing</h4>
                </div> */}
            {/* <div className="btns">
                    <Button variant="contained" onClick={test}>
                        Generate to Word File
                    </Button>
                </div> */}
            <div className="btns generatepdf dynamicBtn">
              <Button variant="contained" onClick={downloadPdf}>
                Generate to PDF file
              </Button>
            </div>
            {/* <div className="tools-container">
                <h4>Tool Box</h4>
                <div>
                  <Button variant="contained" onClick = {handleBeam}>
                    Simply Supported Beam Calculation
                  </Button>
                </div>
                <div>
                  <Button variant="contained">
                    Seach Defects Library
                  </Button>
                </div>

            </div> */}
            {/* <button onClick={addImage}>Set image</button>÷ */}
          </div>

          <div className="tiptapArea">
            {editor && (
              <BubbleMenu
                className="bubble-menu"
                tippyOptions={{ duration: 100 }}
                editor={editor}
              >
                <button
                  onClick={() => editor.chain().focus().toggleBold().run()}
                  className={editor.isActive("bold") ? "is-active" : ""}
                >
                  Bold
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                  className={editor.isActive("italic") ? "is-active" : ""}
                >
                  Italic
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleStrike().run()}
                  className={editor.isActive("strike") ? "is-active" : ""}
                >
                  Strike
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleCode().run()}
                  disabled={
                    !editor.can().chain().focus().toggleCode().run()
                  }
                  className={editor.isActive('code') ? 'is-active' : ''}
                >
                  Code                </button>
                <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                  Clear marks
                </button>
                <button onClick={() => editor.chain().focus().clearNodes().run()}>
                  Clear nodes
                </button>
                <button                  onClick={() => editor.chain().focus().setParagraph().run()}
                  className={editor.isActive('paragraph') ? 'is-active' : ''}
                >
                  Paragraph
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                  className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                  H1
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                  className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                  H2
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                  className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                >
                  H3
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                  className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
                >
                  H4
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                  className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
                >
                  H5
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                  className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
                >
                  H6
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleBulletList().run()}
                  className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                  Bullet list
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleOrderedList().run()}
                  className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                  Ordered list
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                  className={editor.isActive('codeBlock') ? 'is-active' : ''}
                >
                  Code block
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleBlockquote().run()}
                  className={editor.isActive('blockquote') ? 'is-active' : ''}
                >
                  Blockquote
                </button>
                <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                  Horizontal rule
                </button>
                <button onClick={() => editor.chain().focus().setHardBreak().run()}>
                  Hard break
                </button>
                <button
                  onClick={() => editor.chain().focus().undo().run()}
                  disabled={
                    !editor.can().chain().focus().undo().run()
                  }
                >
                  Undo
                </button>
                <button
                  onClick={() => editor.chain().focus().redo().run()}
                  disabled={
                    !editor.can().chain().focus().redo().run()
                  }
                >
                  Redo
                </button>
                <button
                  onClick={() => editor.chain().focus().setColor('#958DF1').run()}
                  className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}
                >
                  Purple
                </button>

              </BubbleMenu>
            )}

            {editor && (
              <FloatingMenu
                className="floating-menu"
                tippyOptions={{ duration: 100 }}
                editor={editor}
              >
                <button
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                  }
                  className={
                    editor.isActive("heading", { level: 1 }) ? "is-active" : ""
                  }
                >
                  H1
                </button>
                <button
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                  }
                  className={
                    editor.isActive("heading", { level: 2 }) ? "is-active" : ""
                  }
                >
                  H2
                </button>
                <button
                  onClick={() =>
                    editor.chain().focus().toggleBulletList().run()
                  }
                  className={editor.isActive("bulletList") ? "is-active" : ""}
                >
                  Bullet list
                </button>
              </FloatingMenu>
            )}

            <EditorContent editor={editor} id="editPdf" />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
      <div className="footer">
      <Footer />
      </div>
    </div>
  );
};

export default Report;
